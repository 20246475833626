import axios from 'axios'

export class LiberacaoDiario {
  static async CadastrarDiario (payload) {
    payload.ano = sessionStorage.getItem("anoSelecionado");
    return axios.post('liberacao/diario', payload);
  }
  static async deletarAutorizacao (id) {
    return axios.delete('liberacao/diario/'+id);
  }
  static async visualizarLiberacao (modulo) {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get(`liberacao/diarios?modulo=${modulo}&ano=${ano}`);
  }
  static async editarLiberacao (payload) {
    return axios.put('liberacao/diario/'+payload.id, payload)
  }
  static async professorVerificarLiberacao (payload) {
    return axios.get('liberacao/diarios/verif/professor/'+payload.servidor_id+'/'+payload.turma_id+'/modulo/'+payload.modulo);
  }

}
