<template>
    <div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="th_per2">{{mes}}</th> {{primeiraPresencas}}
            </tr>
            </thead>
            <tbody>
                <table class="table table-striped">
                <thead>
                    <tr>
                    <th class="th_per1">1º Chamada</th>
                    <th class="th_per1" v-if="segmento == 2 || (segmento == 4 && !ejaEhFundamental)">2º Chamada</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>
                        <div>Presença: {{primeiraPresenca}}</div>
                        <div>Ausência: {{primeiraAusente}}</div>
                    </td>
                    <td  v-if="segmento == 2 || (segmento == 4 && !ejaEhFundamental)">
                        <div>Presença: {{segundaPresenca}}</div>
                        <div>Ausência: {{segundaAusente}}</div>
                    </td>
                    </tr>
                </tbody> 
                </table>
            </tbody>      
        </table> 
    </div>
</template>

<script>
export default {
  name: "presencaAusencia",
  props: {
    mes:null,
    primeiraPresenca: null,
    primeiraAusente: null,
    segundaPresenca: null,
    segundaAusente: null,
    segmento:null,
    ejaEhFundamental:null,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }  
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }

</style>
